<template>
<Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
<WhatsAppButton></WhatsAppButton>
  <div class="privacy-info-sec">
    <div class="container">
      <div class="">
          <div class="col-md-12 mb-5">
               <h3>END USER LICENSE AGREEMENT</h3>
             

          </div>
         
      </div>
    </div>
    </div>
    
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import helper from "@/helper/helper";
import WhatsAppButton from "@/components/WhatsAppButton";
export default {
     name: "UserLicenceAgrement",
      data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },
        {
          path: "/user_licence_agrement",
          name: "User Licence Agrement",
        },
      ],
     
    };
  },
  components: {
    Breadcrumb,
    WhatsAppButton
   
  },
  mounted(){
       helper.backtoTop();
  }
}
</script>
<style scoped>
    .privacy-info-sec h3{
        text-align: center;
        width:100%;
        margin-top: 30px;
    }
    p {
    font-size: 1.6rem;
    line-height: 1.8em;
    color: #666;
    }
    h4{
        margin-top: 20px;
    }
    .privacy_ul{
        list-style-type: disc;
    }
    .privacy_ul li{
        margin-left: 30px;
    }
    .user_ol{
        list-style-type: decimal;
    }
    .privacy_ol li{
         margin-left: 30px;
    }

</style>